import React from "react";

const InfoPage = () => {
    return (
        <section id="accomodation">
            <img className="banner-img" src="../zoopark_skanzen.jpeg" draggable="false"
                 alt="banner"/>
            <h2>Zážitkové bydlení ve skanzenu Stará Ves</h2>
            <p>Zažijte jedinečné bydlení v historickém statku s původním zázemím, ve kterém si budete připadat jako
                v pohádce. Zrekonstruovaný areál se nachází uprostřed skanzenu obklopeného přírodou. Kromě obytné
                budovy je součástí i zázemí domácích zvířat.
            </p>
            <ul>
                <li>cena apartmánu za 4 osoby (přistýlky k dispozici pro dospělé a děti – doplňte níže)</li>
                <li>maximální kapacita: 10 osob</li>
                <li>návštěva zooparku (možnost i ve večerních hodinách v ceně pobytu)</li>
                <li>možnost krmení domácích zvířat dle stanovených pokynů</li>
            </ul>
            <p>
                <strong>Stravování</strong>
                <br/>
                stravování vlastní – k dispozici je plně vybavená kuchyňka (lednice, mikrovlnná trouba).<br/>v
                restauraci – v blízkosti penzionu restaurace
                „<a href="http://upratura.webmium.com/" target="_blank">U Pratura</a>“
            </p>

            <p>e-mail: <a href="mailto:skanzen@zoopark.cz">skanzen&#064;zoopark.cz</a></p>
        </section>
    )
};

export default InfoPage;
